import React from "react";
import { useEffect, useState } from "react";
import ethers from "ethers";
import twiiterIcon from "./assets/images/Twitter.png";
import openseaIcon from "./assets/images/opensea.png";
import etherscan from "./assets/images/etherscan.png";
import pepe from "./assets/images/PEPE95.png";
import logo from "./assets/images/logo.png";
import { Countdown } from "./Countdown";
//import "98.css/dist/98.css";

const MediaNavBar = () => {
  // const [accounts, setAccounts] = useState(false)
  // const isConnected = Boolean(accounts[0]);
  // const connectError = document.querySelector('.errorMsgNeedToConnect')

  // async function connectAccount()  {
  //     if (window.ethereum) {
  //         const accounts = await window.ethereum.request({
  //             method: "eth_requestAccounts",
  //         });
  //         setAccounts(accounts);
  //         // connectError.classList.remove('active')

  //     } else {
  //         // connectError.classList.add('active')
  //     }
  // }

  // function hamburger() {
  //     const hamburger = document.querySelector('.hamburger')
  //     // const navLinks = document.querySelector('.Nav-Links')
  //     const connectBtn = document.querySelector('.Wallet-Connect')
  //     // const musicBtn = document.querySelector('.musicToggleBtn2')

  //     if(hamburger.classList.contains('active')) {

  //         hamburger.classList.remove('active')
  //         // navLinks.classList.remove("active");
  //         connectBtn.classList.remove("active");
  //     } else {
  //         hamburger.classList.add('active')
  //         // navLinks.classList.add("active");
  //         // connectBtn.classList.add("active");
  //     }
  // }

  // useEffect(() => {
  //     const timer = setTimeout(() => {
  //         // const hamburger = document.querySelector('.hamburger')
  //         // const navLinks = document.querySelector('.Nav-Links')
  //         // const connectBtn = document.querySelector('.Wallet-Connect')

  //         hamburger.classList.add("active");
  //         // navLinks.classList.add("active");
  //         // connectBtn.classList.add("active");

  //       console.log('This will run after 1.5 seconds!')
  //     }, 1500);
  //     return () => clearTimeout(timer);
  //   }, []);
  return (
    <div className="medianavbar window">
      <div className="Word-Mark" id="Word-Mark">
        <img src={logo} alt="" />
      </div>

      <div className="middle-cont">
        <div className="left-bar mediaLB">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="middle-bar mediaM">
        <div className="mediacountDown">
        {/* count down */}
        <h1>COUNTDOWN {<Countdown releaseDate={1683406800000} />}</h1>
        {/* count down api in span */}
      </div>
        </div>
        <div className="right-bar mediaRB">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      
      <div className="nav-pepe">
        <img src={pepe} alt="" />
      </div>
    </div>
  );
};

export default MediaNavBar;
