import React, { useState, useEffect } from "react";
import pepe from "../assets/images/Trump_floss.gif";
import demBOYZ from "../assets/images/demboys.png";
import WalletCheckError from "./WalletCheckERROR";
import { ethers, BigNumber } from "ethers";

function WalletCheck() {
  const [accounts, setAccounts] = useState(false);
  const isConnected = Boolean(accounts[0]);
  const [addressValue, setAddressValue] = useState("");
  //connected wallet variable
  var connectedWallet;

  function walCheckDropDown() {
    const dropBtn = document.querySelector(".walletCheckdropDown");
    const walletCheck = document.querySelector(".walletCheck");
    const walletCheckSec = document.querySelector(".walletCheckSec");
    const walletCheckFooter = document.querySelector(".walletCheckFooter");

    if (dropBtn.classList.contains("active")) {
      dropBtn.classList.remove("active");
      walletCheck.classList.remove("active");
      walletCheckSec.classList.remove("active");
      walletCheckFooter.classList.remove("active");
    } else {
      dropBtn.classList.add("active");
      walletCheck.classList.add("active");
      walletCheckSec.classList.add("active");
      walletCheckFooter.classList.add("active");
    }
  }

  async function connectAccount() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(accounts);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      //define connectedWallet
      connectedWallet = await signer.getAddress();
      const res = await fetch(
        "https://api.demboyz.xyz/sale?address=" + connectedWallet,
        {
          mode: "cors",
        }
      );
      const parsed = await res.json();
      console.log(parsed)
      const walletCheckError = document.querySelector(".walletCheckError");
      if (parsed.userWhitelisted) {
        console.log("yo")
        walletCheckError.classList.remove("deactive");
        setTimeout(() => {
          walletCheckError.classList.add("deactive");
        }, 5000);
      }
    } else {
      console.log("COCK")
      //connectError.classList.add('active')
    }
  }

  return (
    <div className="walletCheck">
      <div className="windowOptions mid1">
        <div className="windowBtns">
          <button className="xbtn">
            <div className="x"></div>
            <div className="x"></div>
          </button>
          <button className="walletCheckdropDown" onClick={walCheckDropDown}>
            <div className="drop"></div>
            <div className="drop"></div>
          </button>
        </div>

        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <h2>Dem Boyz</h2>
      </div>

      <div className="walletCheckSec">
        <img src={pepe} alt="" />
      </div>
      <div className="walletCheckFooter">
        <div className="walletCheckInput">
          {/* regular ethers connect function  */}
          {isConnected ? (
            <button>WALLET IS ON ALLOWLIST</button>
          ) : (
            <button onClick={connectAccount}>CHECK WALLET</button>
          )}
        </div>
      </div>

      <WalletCheckError />
    </div>
  );
}

export default WalletCheck;
