import { useCountdown } from "../hooks/useCountdown";

export const Countdown = ({ releaseDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(releaseDate);
  const dateString =
    releaseDate - Date.now() <= 0
      ? "00:00:00"
      : `${days > 9 ? days : `0${days}`}:${hours > 9 ? hours : `0${hours}`}:${
          minutes > 9 ? minutes : `0${minutes}`
        }:${seconds > 9 ? seconds : `0${seconds}`}`;
  return <span>{dateString}</span>;
};
