import React, { useEffect, useState } from "react";

function Legal() {

    function legalDropDown() {
        const dropBtn = document.querySelector(".dropDown");
        const allowList = document.querySelector(".allowList");
        const inputSec = document.querySelector(".inputSec");
        const allowFooter = document.querySelector(".allowFooter");

        const legaldropBtn = document.querySelector(".legaldropDown");
        const legal = document.querySelector(".legal");
        const legalSec = document.querySelector(".legalSec");
        
        const miladydropBtn = document.querySelector(".miladydropDown");
        const miladyAL = document.querySelector(".miladyAL");
        const miladyALInputSec = document.querySelector(".miladyALInputSec");
        const miladyALInput = document.querySelector(".miladyALInput");

        const pepedropBtn = document.querySelector(".pepedropDown");
        const pepeAL = document.querySelector(".pepeAL");
        const pepeALInputSec = document.querySelector(".pepeALInputSec");
        const pepeALInput = document.querySelector(".pepeALInput");
        if (legaldropBtn.classList.contains("active")) {
        legaldropBtn.classList.remove("active");
        legal.classList.remove("active");
        legalSec.classList.remove("active");

        // miladydropBtn.classList.add("active");
        // miladyAL.classList.add("active");
        // miladyALInputSec.classList.add("active");
        // miladyALInput.classList.add("active");

        // dropBtn.classList.remove("active");
        // inputSec.classList.remove("active");
        // allowList.classList.remove("active");
        // allowFooter.classList.remove("active");

        // pepedropBtn.classList.add("active");
        // pepeAL.classList.add("active");
        // pepeALInputSec.classList.add("active");
        // pepeALInput.classList.add("active");
        } 
    }

    function closeLegalAPP() {
      const xBtn = document.querySelector(".legalxbtn");
      const legal = document.querySelector(".legal");
  
      if (xBtn.classList.contains("deactive")) {

      } else {
        legal.classList.add("deactive");
      }
    }

  return (
    <div className="legal deactive active">
      <div className="windowOptions rSide1">
        <div className="windowBtns">
          <button className="legalxbtn" onClick={closeLegalAPP}>
            <div className="x lx"></div>
            <div className="x lx"></div>
          </button>
          <button className="legaldropDown active" onClick={legalDropDown}>
            <div className="drop"></div>
            <div className="drop"></div>
          </button>
        </div>
        <div className="lines rline">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <h2>Legal</h2>
      </div>
      <div className="legalSec active">
        <h1>PEPE DISCLAIMERS AND TERMS</h1>
        <p id="initDesclaimer">
          BY USING THIS SITE, YOU AGREE TO THE FOLLOWING TERMS AND DISCLAIMERS.
          <br />
          <br />
          NOTHING DISPLAYED, SOLD, DISTRIBUTED, OR MINTED ON THIS WEBSITE
          CONSTITUTES A FINANCIAL PRODUCT OR INVESTMENT IN ANYWAY. ALL SALES,
          DISTRIBUTIONS, PURCHASES, MINTS, ETC. ARE ALL COLLECTIBLES AND HOLD NO
          MONETARY VALUE. THERE IS NO ROADMAP AND PURCHASERS ARE ENTITLED TO
          NOTHING MORE THAN THE NFT ITSELF.
        </p>

        <h2>NFT Ownership Disclaimer</h2>
        <p>
          If at any time you sell, trade, donate, give away, or transfer the NFT
          to a new owner, you as the previous owner forego and relinquish any
          legal rights or rights to any legal action regarding your previous
          association with the NFT.
        </p>

        <h2>Awareness of Risks.</h2>
        <p>NFTs, cryptocurrency tokens, and ICO projects are highly speculative
          and risky by nature. They are experimental, unregulated, and prone to
          dramatic price fluctuations, fraud, and incomplete documentation.
          <br/>
          <br/>
          Purchasing NFTs, tokens, or projects such as this should only be done
          by individuals with substantial technical knowledge of the offering
          and careful due diligence should be conducted on the underlying
          projects, network, tokens, and team. It is important to understand
          that by purchasing this NFT or similar tokens you may lose the entire
          value of your purchase, including any associated fees.
        </p>

        <h2>
          Limitations of Liability for Failed transactions, bugs, and gas fees.
        </h2>
        <p>By participating in the minting of PEPE NFTs, individuals acknowledge
          and agree to waive any claims against the Creative Team for any losses
          incurred as a result of their participation, including gas fees for
          unsuccessful transactions, or excessive gas fees caused by website or
          smart contract glitches, as well as any NFT losses resulting from
          website or smart contract glitches or any secondary value losses. It
          is important to note that the project is solely for artistic purposes,
          and does not represent anything more than that.
        </p>

        <h2>No Future Promises or Guarantees</h2>
        <p>You agree that your purchase of one or more NFT’s from our initial
          launch of 6000 NFT’s is all you are guaranteed to receive with your
          initial purchase, whether through primary or secondary channels. You
          agree that you are not relying on any future commitments by The PEPE
          Team in participating in our NFT launch.
        </p>

        <h2>No Guarantees of Value</h2>
        <p>PEPE NFTs were created purely as collectibles, not as investment
          vehicles or substitutes for cryptocurrency. We make absolutely no
          promise or guarantee that these NFTs will subsequently retain monetary
          value in fiat, cash or cryptocurrency.
        </p>

        <h2>Class Action Waiver, Jurisdiction and Choice of Law</h2>
        <p>You agree to waive any right to class action dispute regarding the
          PEPE NFT and agree to initiate any legal claim on a personal basis.
          THIS AGREEMENT SHALL BE GOVERNED BY THE LAWS OF THE X, WITHOUT GIVING
          EFFECT TO ANY PRINCIPLES REGARDING CONFLICT OF LAWS.
        </p>

        <h2>Experimental Technologies</h2>
        <p>The Website employs experimental smart contract and blockchain
          technology, including non-fungible tokens, cryptocurrencies, consensus
          algorithms, and decentralized or peer-to-peer networks and systems for
          various transactions such as primary sales, secondary market sales,
          listings, offers, bids, acceptances, and other operations. 
          <br/>
          <br/>
          However,these technologies are speculative, experimental, and risky, and
          holders must acknowledge and accept this fact. Bugs, malfunctions,
          timing errors, hacking and theft, or changes to the protocol rules of
          the Ethereum blockchain can significantly impact smart contracts, and
          holders may risk a total loss, forfeiture of their digital currency or
          NFTs or lost opportunities to buy or sell NFTs. The PEPE team is not
          liable or responsible for any such failures, risks, or uncertainties.
        </p>
      </div>
    </div>
  );
}

export default Legal;
