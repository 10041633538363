import React from "react";
import pepe from "./assets/images/PEPE95.png";

function DesktopIcon() {

    function closeAllowAPP() {
        const xBtn = document.querySelector('.xbtn');
        const allowList = document.querySelector('.allowList');

        if(xBtn.classList.contains('deactive')){
            xBtn.classList.remove('deactive');
            allowList.classList.remove('deactive');
        } 
    }

    function closeCounterAPP() {
        const xBtn = document.querySelector('.counterxbtn');
        const counter = document.querySelector('.counter');

        if(xBtn.classList.contains('deactive')){
            xBtn.classList.remove('deactive');
            counter.classList.remove('deactive');
        } 
    }

 
    function closePEPEAPP() {
        const xBtn = document.querySelector('.pepexbtn');
        const pepeAL = document.querySelector('.pepeAL');

        if(xBtn.classList.contains('deactive')){
            xBtn.classList.remove('deactive');
            pepeAL.classList.remove('deactive');
        } 
    }
    function closeMiladyAPP() {
        const xBtn = document.querySelector('.miladyxbtn');
        const miladyAL = document.querySelector('.miladyAL');

        if(xBtn.classList.contains('deactive')){
            xBtn.classList.remove('deactive');
            miladyAL.classList.remove('deactive');
        } 
    }

    function closeLegalAPP() {
        const xBtn = document.querySelector('.legalxbtn');
        const legal = document.querySelector('.legal');

        if(xBtn.classList.contains('deactive')){

        } else {
            legal.classList.remove('deactive');
        }
    }

    return(
        <div className="desktopIcon">
            <div className="iconSet1">
                <div className="icon">
                <a href="https://twitter.com/demboyz_xyz?s=21&t=ywU3O_mztomY9QlFlK3q7A">
                    <img src={pepe} alt="" />
                    <p>Twitter.exe</p>
                </a>
                </div>

                <div className="icon">
                <a href="https://opensea.io/collection/dem-boyz-1">
                    <img src={pepe} alt="https://etherscan.io/address/0x8b85406d215064588f0b6917ec18beddab9fd9ee" />
                    <p>Opensea.exe</p>
                </a>
                </div>
                
                <div className="icon" onClick={closeLegalAPP}>
                    <img src={pepe} alt="" />
                    <p>TOS.pdf</p>
                </div>
                
                <div className="icon">
                <a href="">
                    <img src={pepe} alt="https://etherscan.io/address/0x8b85406d215064588f0b6917ec18beddab9fd9ee" />
                    <p>Etherscan.exe</p>
                </a>
                </div>

                <div className="icon">
                <a href="https://demboyztoken.xyz/">
                    <img src={pepe} alt="" />
                    <p> $DemBoyz </p>
                </a>
                </div>
            </div>
            
            {/*<div className="iconSet2">
                <div className="icon" onClick={closeAllowAPP}>
                    <img src={pepe} alt="" />
                    <p>Allowlist.docx</p>
                </div>

                <div className="icon" onClick={closeCounterAPP}>
                    <img src={pepe} alt="" />
                    <p>Counter.exe</p>
                </div>

                <div className="icon" onClick={closeMiladyAPP}>
                    <img src={pepe} alt="" />
                    <p>MiladyWL.docx</p>
                </div>

                <div className="icon" onClick={closePEPEAPP}>
                    <img src={pepe} alt="" />
                    <p>$PEPEWL.docx</p>
                </div>
            </div>*/}
        </div>
    )
}
export default DesktopIcon;