import logo from './logo.svg';
import Landing from "./components/Landing.jsx"
import './App.scss';


function App() {
  return (
    <div className="App">
     <Landing />
    </div>
  );
}

export default App;
