import React, { useState, useEffect, useCallback } from "react";
import pepe from "../assets/images/Trump_floss.gif";
import demBOYZ from "../assets/images/demboys.png";
import pepeSwitch from "../assets/images/pepeSwitch.gif";
import { ethers, BigNumber } from "ethers";
//@ts-nocheck
import safemath from "safemath";


//abi & bytecode import
import ContractABI from "../Contract.json";

// Defined contract address
const ContractAddress = "0x8b85406d215064588f0b6917EC18bEDDAB9FD9ee"; //testnet
//const TestContractAddress = ""; //testnet

function DemBoyz() {
  const [ethereum, setEthereum] = useState(undefined);
  const [contract, setContract] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const [saleState, setSaleState] = useState({
    publicLive: false,
    whitelistLive: false,
    mintedCount: 0,
    userWhitelisted: false,
  });

  const onAccountChange = async (accounts) => {
    const account = accounts[0];
    setAddress(account);
    const sale = await getSale(account);
    setSaleState({
      ...sale,
    });
  };

  const init = async () => {
    if (window.ethereum) {
      setEthereum(window.ethereum);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (ethereum !== null && ethereum !== undefined && ContractAddress !== "") {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      setContract(new ethers.Contract(ContractAddress, ContractABI, signer));
      setAddress(ethereum.selectedAddress);
      ethereum.on("accountsChanged", onAccountChange);
      return () => {
        ethereum.removeListener("accountsChanged", onAccountChange);
      };
    }
  }, [ethereum, ethereum?.selectedAddress]);

  const isButtonDisabled = () => {
    if (saleState.userWhitelisted && saleState.whitelistLive) {
      return false;
    }
    if (!saleState.userWhitelisted && saleState.whitelistLive) {
      return true;
    }
    if (saleState.publicLive) {
      return false;
    }
    return true;
  };

  const getButtonText = useCallback(() => {
    if (saleState.publicLive) {
      return "MINT DEM BOYZ";
    }
    if (saleState.userWhitelisted && !saleState.whitelistLive) {
      return "You're On The Whitelist. Waiting For Sale.";
    }
    if (saleState.userWhitelisted && saleState.whitelistLive) {
      return "MINT DEM BOYZ";
    }
    if (!saleState.userWhitelisted) {
      return "You're Not On The Whitelist.";
    }
    return "WAITING FOR SALE";
  }, [saleState]);

  const getSale = async (address) => {
    try {
      const res = await fetch(
        `https://api.demboyz.xyz/sale?address=${
          address !== undefined ? address : ""
        }`
      );
      const parsed = await res.json();
      return parsed;
    } catch {
      return saleState;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const sale = await getSale(address);
        if ("whitelistLive" in sale) {
          setSaleState({
            publicLive: sale.publicLive,
            whitelistLive: sale.whitelistLive,
            userWhitelisted: sale.userWhitelisted,
            mintedCount: sale.mintedCount,
          });
        }
      } catch {}
    }, 4000);

    return () => clearInterval(intervalId);
  }, [address]);

  function demboyzDropDown() {
    const dropBtn = document.querySelector(".demboyzdropDown");
    const demboyz = document.querySelector(".demboyz");
    const demboyzSec = document.querySelector(".demboyzSec");
    const demboyzFooter = document.querySelector(".demboyzFooter");

    if (dropBtn.classList.contains("active")) {
      dropBtn.classList.remove("active");
      demboyz.classList.remove("active");
      demboyzSec.classList.remove("active");
      demboyzFooter.classList.remove("active");
    } else {
      dropBtn.classList.add("active");
      demboyz.classList.add("active");
      demboyzSec.classList.add("active");
      demboyzFooter.classList.add("active");
    }
  }

  const [mintAmount, setMintAmount] = useState(1);
  const [accounts, setAccounts] = useState(false);
  const isConnected = Boolean(accounts[0]);

  const handleQuantityChange = (value) => {
    setMintAmount(value);
  };

  const handleMintClick = async () => {
    await mintFlow(mintAmount);
    setMintAmount(0);
  };
  const mintFlow = async (mintAmount) => {
    if (address !== undefined && contract !== undefined) {
      if (saleState.mintedCount < 9999) {
        if (saleState.whitelistLive && saleState.userWhitelisted) {
          const res = await fetch(
            `https://api.demboyz.xyz/proof?address=${address}`,
            {
              mode: "cors",
            }
          );
          const parsed = await res.json();
          try {
            //Allowlist mint function call
            const response = await contract.whitelistMint(
              BigNumber.from(mintAmount),
              parsed.proof,
              {
                value: ethers.utils.parseEther((0.015 * mintAmount).toString()),
              }
            );
            console.log("response: ", response);
          } catch (err) {
            console.log("error: ", err);
          }
        } else if (saleState.publicLive) {
          try {
            //public mint function call
            const response = await contract.mint(mintAmount, {
              value: ethers.utils.parseEther((safemath.safeMule("0.01", `${mintAmount}`)).toString()),
            });
            console.log("response: ", response);
          } catch (err) {
            console.log("error: ", err);
          }
        }
      }
    }
  };

  // initialize wallet load
  useEffect(() => {
    if (window.ethereum) {
    }
  }, []);

  var connectedWallet;

  const connectAccount = useCallback(() => {
    if (ethereum !== null && ethereum !== undefined) {
      ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then(onAccountChange)
        .catch((error) => {
          console.log("Error connecting wallet");
        });
      setAccounts(accounts);
      const walletConnect = document.getElementById("walletConnect");
      const amountSelect = document.querySelector(".amountSelect");
      const dbmintbtn = document.getElementById("dbmintbtn");
      const mintText = document.querySelector(".mintText");

      walletConnect.classList.add("deactive");
      amountSelect.classList.remove("deactive");
      dbmintbtn.classList.remove("deactive");
      mintText.classList.remove("deactive");
    } else {
      //connectError.classList.add('active')
    }
  }, [ethereum]);

  return (
    <div className="demboyz">
      <div className="windowOptions mid1">
        <div className="windowBtns">
          <button className="xbtn">
            <div className="x"></div>
            <div className="x"></div>
          </button>
          <button className="demboyzdropDown" onClick={demboyzDropDown}>
            <div className="drop"></div>
            <div className="drop"></div>
          </button>
        </div>

        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <h2>Dem Boyz</h2>
      </div>

      <div className="demboyzSec">
        <img src={pepeSwitch} alt="" />
      </div>
      <div className="demboyzFooter">
        <div className="demboyzInput">
          <div className="amountSelect deactive">
            <button
              id="1"
              onClick={() => handleQuantityChange(1)}
              className={mintAmount === 1 ? "clicked" : ""}
            >
              1
            </button>
            <button
              id="2"
              onClick={() => handleQuantityChange(2)}
              className={mintAmount === 2 ? "clicked" : ""}
            >
              2
            </button>
            <button
              id="3"
              onClick={() => handleQuantityChange(3)}
              className={mintAmount === 3 ? "clicked" : ""}
            >
              3
            </button>
            <button
              id="4"
              onClick={() => handleQuantityChange(4)}
              className={mintAmount === 4 ? "clicked" : ""}
            >
              4
            </button>
            <button
              id="5"
              onClick={() => handleQuantityChange(5)}
              className={mintAmount === 5 ? "clicked" : ""}
            >
              5
            </button>
            <button
              id="6"
              onClick={() => handleQuantityChange(6)}
              className={mintAmount === 6 ? "clicked" : ""}
            >
              6
            </button>
            <button
              id="7"
              onClick={() => handleQuantityChange(7)}
              className={mintAmount === 7 ? "clicked" : ""}
            >
              7
            </button>
            <button
              id="8"
              onClick={() => handleQuantityChange(8)}
              className={mintAmount === 8 ? "clicked" : ""}
            >
              8
            </button>
            <button
              id="9"
              onClick={() => handleQuantityChange(9)}
              className={mintAmount === 9 ? "clicked" : ""}
            >
              9
            </button>
            <button
              id="10"
              onClick={() => handleQuantityChange(10)}
              className={mintAmount === 10 ? "clicked" : ""}
            >
              10
            </button>
          </div>
          <button
            id="dbmintbtn"
            className="textColor deactive"
            onClick={handleMintClick}
            disabled={isButtonDisabled()}
          >
            {getButtonText()}
          </button>
          {/* track the remaining supply here */}
          <div className="mintText deactive">
            <div className="mintPrice">
              <p>PUBLIC PRICE:</p>
              <h2>0.01</h2>
            </div>
            <div className="minted">
              <p>MINTED:</p>
              <h2>{saleState.mintedCount}/6969</h2>
            </div>
          </div>
          <button id="walletConnect" onClick={connectAccount}>
            Connect Wallet
          </button>
        </div>
      </div>
    </div>
  );
}

export default DemBoyz;
