import React from "react";
import milady from "../assets/images/Milady_Eyes.png";
import miladytext from "../assets/images/MILADY WL.png";
import { useState } from "react";
import { useEffect } from "react";

function MiladyAL() {
  const [miladyValue, setMiladyValue] = useState("");
  const [buttonValue, setButtonValue] = useState("SUBMIT");

  function miladyDropDown() {
    const dropBtn = document.querySelector(".dropDown");
    const allowList = document.querySelector(".allowList");
    const inputSec = document.querySelector(".inputSec");
    const allowFooter = document.querySelector(".allowFooter");

    const legaldropBtn = document.querySelector(".legaldropDown");
    const legal = document.querySelector(".legal");
    const legalSec = document.querySelector(".legalSec");
    
    const miladydropBtn = document.querySelector(".miladydropDown");
    const miladyAL = document.querySelector(".miladyAL");
    const miladyALInputSec = document.querySelector(".miladyALInputSec");
    const miladyALInput = document.querySelector(".miladyALInput");

    const pepedropBtn = document.querySelector(".pepedropDown");
    const pepeAL = document.querySelector(".pepeAL");
    const pepeALInputSec = document.querySelector(".pepeALInputSec");
    const pepeALInput = document.querySelector(".pepeALInput");
    if (miladydropBtn.classList.contains("active")) {
      miladydropBtn.classList.remove("active");
      miladyAL.classList.remove("active");
      miladyALInputSec.classList.remove("active");
      miladyALInput.classList.remove("active");

      // legaldropBtn.classList.remove("active");
      // legal.classList.remove("active");
      // legalSec.classList.remove("active");

      dropBtn.classList.add("active");
      inputSec.classList.add("active");
      allowList.classList.add("active");
      allowFooter.classList.add("active");

      pepedropBtn.classList.add("active");
      pepeAL.classList.add("active");
      pepeALInputSec.classList.add("active");
      pepeALInput.classList.add("active");
    } 
  }
  /*
  function closeMiladyAPP() {
    const xBtn = document.querySelector(".miladyxbtn");
    const miladyAL = document.querySelector(".miladyAL");

    if (xBtn.classList.contains("deactive")) {
    } else {
      xBtn.classList.add("deactive");
      miladyAL.classList.add("deactive");
    }
  }*/

  const miladySubmitHandler = async () => {
    if (miladyValue.length >= 40 && miladyValue.length <= 42) {
      try {
        const res = await fetch(
          `https://api.demboyz.xyz/allowlist?address=${miladyValue}`
        );
        const parsed = await res.json();
        if (parsed.success) {
          setButtonValue("Success");
        } else {
          setButtonValue("Error");
        }
      } catch {
        setButtonValue("Error")
      }
    }
  };

  const miladyOnChangeHandler = (event) => {
    setMiladyValue(event.target.value);
  };

  useEffect(() => {
    if (buttonValue === "Success" || buttonValue === "Error") {
      setTimeout(() => {
        setButtonValue("Whitelist");
      }, 3000);
    }
  }, [buttonValue]);

  return (
    <div className="miladyAL active">
      <div className="windowOptions lSide1">
        <div className="windowBtns">
          <button className="miladyxbtn" >
            <div className="x"></div>
            <div className="x"></div>
          </button>
          <button className="miladydropDown active" onClick={miladyDropDown}>
            <div className="drop"></div>
            <div className="drop"></div>
          </button>
        </div>
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <h2 id="SideWL2">Milady WL</h2>
      </div>
      <div className="miladyALInputSec active">
          <h2>Milady Holders WL</h2>
      </div>
      <div className="miladyALInput active">
        <input
          type="text"
          placeholder="WALLET ADDRESS HERE "
          onChange={miladyOnChangeHandler}
          value={miladyValue}
        />
        <button onClick={miladySubmitHandler}>{buttonValue}</button>
      </div>
    </div>
  );
}

export default MiladyAL;
