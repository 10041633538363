import React from "react";
import { useEffect, useState } from 'react';
import ethers from 'ethers';
import Navbar from "./NavBar";
import DemBoyz from "./minting/DemBoyz";
import LandingBG from "./assets/images/landingBG.gif"
import Allowlist from "./Whitelist/AllowList";
import Counter from "./Counter";
import Delay from "./Delay";
import DesktopIcon from "./DesktopIcons";
import PEPEAL from "./Whitelist/PEPEAL";
import MiladyAL from "./Whitelist/MiladyAL";
import PEPESign from "./assets/images/Sign_1.gif"
import mintgif from "./assets/images/mintgif.gif"
import mintgif2 from "./assets/images/mintgif2.gif"
import MediaNavBar from "./mediaNav";
import Legal from "./Legal";
import WalletCheck from "./walletCheck/WalletCheck";
const Landing = () => {
    const backgroundImageStyle = {
        backgroundImage: `url(${LandingBG})`,
    };

    useEffect(()=> {
        setTimeout(()=> {
            // const PEPESign = document.getElementById("PEPESign");
            // const mintgif = document.getElementById("mintgif");
            // const mintgif2 = document.getElementById("mintgif2");

    
            // PEPESign.classList.add('pop');
            // mintgif.classList.add('pop');
            // mintgif2.classList.add('pop');
        }, 3000)
        setTimeout(()=> {
            // const mintgif2 = document.getElementById("mintgif2");

            // mintgif2.classList.remove('pop');
        }, 6000)
    })

            //<WalletCheck/>
            //<Info />
            //<Allowlist /> 
            //<Legal />
            //<MiladyAL />
            //<PEPEAL />
            //<DemBoyz />

    return (
        <div className="Landing">
            <img id="landingBG" src={LandingBG} alt="" />
            <MediaNavBar />
            <Navbar />
            {/* <img id="PEPESign" src={PEPESign} alt="" /> */}
            {/* <img id="mintgif" src={mintgif} alt="" />
            <img id="mintgif2" src={mintgif2} alt="" /> */}
            <DesktopIcon />
            {/* <Counter /> */}
            {/* <Delay /> */}
            {/* <Allowlist />  */}
            
            {/* mint component is DemBoyz */}
            <DemBoyz />
            
            <Legal />
            {/* <MiladyAL />
            <PEPEAL /> */}

            {/* wallet component */}
            {/* <WalletCheck /> */}
        </div>
    )
};

export default Landing;